const DIRECTIONS = Object.seal({
  IN: 'in',
  OUT: 'out',
});

class Component {
  onCreate() {
    this.state = {
      notifications: [],
      direction: DIRECTIONS.IN,
    };
  }

  closeNotification() {
    this.setState('direction', DIRECTIONS.OUT);
  }

  handleAnimationEnd(event) {
    const { dataset } = event.target;

    if (dataset.direction === DIRECTIONS.OUT) {
      const { notifications } = this.state;

      notifications.pop();

      this.setState('direction', DIRECTIONS.IN);
      this.setStateDirty('notifications');
    }
  }

  handleNotify({ data, level, isError = false }) {
    const { notifications } = this.state;
    const {
      level: dataLevel,
      body,
      description,
      message,
      body_html,
      description_html,
      message_html,
      error_description_html,
      error_description,
      reqId,
    } = data;

    const hasNotifications = Boolean(notifications.length);

    notifications.push({
      isError,
      level: dataLevel || level,
      body: body || description || message || error_description,
      body_html:
        body_html || description_html || message_html || error_description_html,
      reqId,
    });

    if (hasNotifications) {
      this.setState('direction', DIRECTIONS.OUT);
    } else {
      this.setState('direction', DIRECTIONS.IN);
      this.setStateDirty('notifications');
    }
  }

  handleClick(event) {
    const { target } = event;
    const { nodeName } = target;

    if (nodeName === 'BUTTON') {
      this.closeNotification();
    }
  }

  // Public methods.
  notify(data, level = 'success') {
    this.handleNotify({ data, level });
  }

  error(data) {
    this.handleNotify({ data, level: 'danger', isError: true });
  }

  onError(error) {
    this.error(error);
  }
}

export default Component;
