/**
 * Convert a string into a Buffer-like object.
 * @param {String} str The string to convert
 * @returns {ArrayBuffer}
 */
export function stringToBuffer(str) {
  return Uint8Array.from(str, (c) => c.charCodeAt(0)).buffer;
}

/**
 * Convert a Buffer-like object into a string.
 * @param {ArrayBuffer} buf The buffer to convert
 * @returns {String}
 */
export function bufferToString(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

/**
 * Join together two buffers.
 * @param {ArrayBuffer} buff1
 * @param {ArrayBuffer} buff2
 * @returns {ArrayBuffer}
 */
export function concatenateBuffers(buff1, buff2) {
  const joinedBuff = new Uint8Array(buff1.byteLength + buff2.byteLength);

  joinedBuff.set(new Uint8Array(buff1), 0);
  joinedBuff.set(new Uint8Array(buff2), buff1.byteLength);

  return joinedBuff;
}
