class Component {
  /**
   * @returns {Object}
   */
  getBoundingClientRect() {
    return this.getEl('box').getBoundingClientRect();
  }

  /**
   * @returns {Object}
   */
  getTitleBoundingClientRect() {
    const el = this.getEl('title');
    if (el) {
      return el.getBoundingClientRect();
    }
  }
}

export default Component;
