/**
 * Minimum password length in characters.
 * @constant {Number}
 */
export const OLD_MIN_PASSWORD_LENGTH = 10;

/**
 * Minimum password length in characters.
 * @constant {Number}
 */
export const MIN_PASSWORD_LENGTH = 12;

/**
 * Maximum password length in characters.
 * @constant {Number}
 */
export const MAX_PASSWORD_LENGTH = 128;
