$ const { class: classes, title, titleClass, isFullHeight, isBoxed = true, titleHasRule = false, boxAttrs = {}, titleCapitalized = true } = input;

<div key="box" class:no-update=["app-box", classes, isFullHeight && "is-fullheight"] ...boxAttrs>
  <div class:no-update=[isBoxed && "box", isFullHeight && "is-fullheight"]>
    <if(title)>
      <if(titleClass)>
        <div class=titleClass>
          <h2 id:scoped="box-title" no-update key="title" class=["title is-5", titleHasRule ? "mb-0" : "mb-4", titleCapitalized && "is-capitalized"]>$!{title}</h2>
          <if(titleHasRule)>
            <hr no-update class="box-title-rule mb-4">
          </if>
        </div>
      </if>
      <else>
        <h2 id:scoped="box-title" no-update key="title" class=["title is-5", titleHasRule ? "mb-0" : "mb-4", titleCapitalized && "is-capitalized"]>$!{title}</h2>
        <if(titleHasRule)>
          <hr no-update class="box-title-rule mb-4">
        </if>
      </else>
    </if>
    <${input.renderBody} />
  </div>
</div>
